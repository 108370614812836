/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzI4NzRmMCIsIj9lcjwjYmZkNWZiIiwiO2VyPCMxODU3ZTl$LCIlPmBePCM3OTc5NzkiLCI~ZXI8I2Q3ZDdkNyIsIjtlcjwjNWM1YzVjfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/

// @import "~@angular/material/theming";
// @import'~swiper/swiper-bundle';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import '@angular/material/prebuilt-themes/indigo-pink.css';
// or
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import '~bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, "", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "", 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, "", 0em),
  title: mat-typography-level(20px, 32px, 500, "", 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, "", 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, "", 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, "", 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, "", 0.0179em),
  button: mat-typography-level(14px, 14px, 500, "", 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, "", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, "", 1.5px),
);

// @import url();
// Foreground Elements

// Light Theme Text
// $dark-text: #000000;
// $dark-primary-text: rgba($dark-text, 0.87);
// $dark-accent-text: rgba($dark-primary-text, 0.54);
// $dark-disabled-text: rgba($dark-primary-text, 0.38);
// $dark-dividers: rgba($dark-primary-text, 0.12);
// $dark-focused: rgba($dark-primary-text, 0.12);

// $mat-light-theme-foreground: (
//   base: black,
//   divider: $dark-dividers,
//   dividers: $dark-dividers,
//   disabled: $dark-disabled-text,
//   disabled-button: rgba($dark-text, 0.26),
//   disabled-text: $dark-disabled-text,
//   elevation: black,
//   secondary-text: $dark-accent-text,
//   hint-text: $dark-disabled-text,
//   accent-text: $dark-accent-text,
//   icon: $dark-accent-text,
//   icons: $dark-accent-text,
//   text: $dark-primary-text,
//   slider-min: $dark-primary-text,
//   slider-off: rgba($dark-text, 0.26),
//   slider-off-active: $dark-disabled-text,
// );

// Dark Theme text
// $light-text: #ffffff;
// $light-primary-text: $light-text;
// $light-accent-text: rgba($light-primary-text, 0.7);
// $light-disabled-text: rgba($light-primary-text, 0.5);
// $light-dividers: rgba($light-primary-text, 0.12);
// $light-focused: rgba($light-primary-text, 0.12);

// $mat-dark-theme-foreground: (
//   base: $light-text,
//   divider: $light-dividers,
//   dividers: $light-dividers,
//   disabled: $light-disabled-text,
//   disabled-button: rgba($light-text, 0.3),
//   disabled-text: $light-disabled-text,
//   elevation: black,
//   hint-text: $light-disabled-text,
//   secondary-text: $light-accent-text,
//   accent-text: $light-accent-text,
//   icon: $light-text,
//   icons: $light-text,
//   text: $light-text,
//   slider-min: $light-text,
//   slider-off: rgba($light-text, 0.3),
//   slider-off-active: rgba($light-text, 0.3),
// );

// Background config
// Light bg
// $light-background: #fafafa;
// $light-bg-darker-5: darken($light-background, 5%);
// $light-bg-darker-10: darken($light-background, 10%);
// $light-bg-darker-20: darken($light-background, 20%);
// $light-bg-darker-30: darken($light-background, 30%);
// $light-bg-lighter-5: lighten($light-background, 5%);
// $dark-bg-tooltip: lighten(#2c2c2c, 20%);
// $dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
// $dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// $mat-light-theme-background: (
//   background: $light-background,
//   status-bar: $light-bg-darker-20,
//   app-bar: $light-bg-darker-5,
//   hover: $dark-bg-alpha-4,
//   card: $light-bg-lighter-5,
//   dialog: $light-bg-lighter-5,
//   tooltip: $dark-bg-tooltip,
//   disabled-button: $dark-bg-alpha-12,
//   raised-button: $light-bg-lighter-5,
//   focused-button: $dark-focused,
//   selected-button: $light-bg-darker-20,
//   selected-disabled-button: $light-bg-darker-30,
//   disabled-button-toggle: $light-bg-darker-10,
//   unselected-chip: $light-bg-darker-10,
//   disabled-list-option: $light-bg-darker-10,
// );

// // Dark bg
// $dark-background: #2c2c2c;
// $dark-bg-lighter-5: lighten($dark-background, 5%);
// $dark-bg-lighter-10: lighten($dark-background, 10%);
// $dark-bg-lighter-20: lighten($dark-background, 20%);
// $dark-bg-lighter-30: lighten($dark-background, 30%);
// $light-bg-alpha-4: rgba(#fafafa, 0.04);
// $light-bg-alpha-12: rgba(#fafafa, 0.12);

// // Background palette for dark themes.
// $mat-dark-theme-background: (
//   background: $dark-background,
//   status-bar: $dark-bg-lighter-20,
//   app-bar: $dark-bg-lighter-5,
//   hover: $light-bg-alpha-4,
//   card: $dark-bg-lighter-5,
//   dialog: $dark-bg-lighter-5,
//   tooltip: $dark-bg-lighter-20,
//   disabled-button: $light-bg-alpha-12,
//   raised-button: $dark-bg-lighter-5,
//   focused-button: $light-focused,
//   selected-button: $dark-bg-lighter-20,
//   selected-disabled-button: $dark-bg-lighter-30,
//   disabled-button-toggle: $dark-bg-lighter-10,
//   unselected-chip: $dark-bg-lighter-20,
//   disabled-list-option: $dark-bg-lighter-10,
// );

// Compute font config
// @include mat-core($fontConfig);

// Theme Config

// body {
//   --primary-color: #2874f0;
//   --primary-lighter-color: #bfd5fb;
//   --primary-darker-color: #1857e9;
//   --text-primary-color: #{$light-primary-text};
//   --text-primary-lighter-color: #{$dark-primary-text};
//   --text-primary-darker-color: #{$light-primary-text};
// }
// $mat-primary: (
//   main: #2874f0,
//   lighter: #bfd5fb,
//   darker: #1857e9,
//   200: #2874f0,
//   // For slide toggle,
//   contrast:
//     (
//       main: $light-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-primary: mat-palette($mat-primary, main, lighter, darker);

// body {
//   --accent-color: #797979;
//   --accent-lighter-color: #d7d7d7;
//   --accent-darker-color: #5c5c5c;
//   --text-accent-color: #{$light-primary-text};
//   --text-accent-lighter-color: #{$dark-primary-text};
//   --text-accent-darker-color: #{$light-primary-text};
// }
// $mat-accent: (
//   main: #797979,
//   lighter: #d7d7d7,
//   darker: #5c5c5c,
//   200: #797979,
//   // For slide toggle,
//   contrast:
//     (
//       main: $light-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-accent: mat-palette($mat-accent, main, lighter, darker);

// body {
//   --warn-color: #ff0000;
//   --warn-lighter-color: #ffb3b3;
//   --warn-darker-color: #ff0000;
//   --text-warn-color: #{$light-primary-text};
//   --text-warn-lighter-color: #{$dark-primary-text};
//   --text-warn-darker-color: #{$light-primary-text};
// }
// $mat-warn: (
//   main: #ff0000,
//   lighter: #ffb3b3,
//   darker: #ff0000,
//   200: #ff0000,
//   // For slide toggle,
//   contrast:
//     (
//       main: $light-primary-text,
//       lighter: $dark-primary-text,
//       darker: $light-primary-text,
//     ),
// );
// $theme-warn: mat-palette($mat-warn, main, lighter, darker);
// $theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
// $altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// // Theme Init
// @include angular-material-theme($theme);

// .theme-alternate {
//   @include angular-material-theme($altTheme);
// }

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: none !important;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Roboto";
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Oswald&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
:root {
  --white-color: white;
  --success-color: green;
}
.Roboto {
  font-family: "Roboto", sans-serif !important;
}
.OpenSans {
  font-family: "Open Sans", sans-serif !important;
}
.Lato {
  font-family: "Lato", sans-serif !important;
}
.Montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.SourceSansPro {
  font-family: "Source Sans Pro", sans-serif !important;
}
.jost{
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  // font-weight: <weight>;
  font-style: normal;
}

.text-primary-color {
  color: var(--primary-color) !important;
}

.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
 
  
}
.Poppins{
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
}

.outlet-100 {
  min-height: 100vh;
  overflow: hidden;
}
.outlet-90 {
  min-height: calc(100vh - 64px);
  overflow: hidden;
  // padding-top: 64px;
}
.box-shadow {
  transition-duration: 0.3s;
}
.box-shadow:hover {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale3d(1.2);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.mat-select-panel {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.mat-raised-button[disabled][disabled] {
  background-color: rgb(44 44 44 / 44%) !important;
  color: white !important;
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: white;
  }
}

.main-div-pad {
  padding: 30px 80px;
}
.main-div-pad-cat {
  padding: 10px 80px;
}
.main-div-pad-x {
  padding: 10px !important;
}
.product-title{
  font-size:28px;
}
.heading {
  font-size: 24px;
}
.sub-heading {
  font-size: 20px;
}
.fs-md {
  font-size: 19px;
}
.fs-sm {
  font-size: 14px;
}
.fs-x-sm {
  font-size: 12px;
}
.paragragh {
  font-size: 16px;
}
.m-0 {
  margin: 0px !important;
}
.m-y5-x0 {
  margin: 5px 0px !important;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.p-y5-x10{
  padding:5px 10px;
}

//border
.br-n {
  border: none;
}
.b-1lc {
  border: 1px solid lightgray;
}
.br-50 {
  border-radius: 50%;
}
.br-10 {
  border-radius: 10px;
}
.br-5 {
  border-radius: 5px;
}
.br-0 {
  border-radius: 0px !important;
}
.link {
  cursor: pointer;
}
.t-a-c {
  text-align: center;
}
.t-a-e {
  text-align: end;
}
.text-d-ul {
  text-decoration: underline;
}
.t-d-lt {
  text-decoration: line-through;
}
.bg-white {
  background-color: white;
}
.t-tr-u {
  text-transform: uppercase;
}
.t-tr-c {
  text-transform: capitalize;
}
.t-tr-l {
  text-transform: lowercase;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.ls-1 {
  letter-spacing: 1px;
}
.zoom {
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
.zoom:hover {
  -moz-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.text-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px; /* fallback */
  min-height: 36px;
  max-height: 36px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.text-ellipsis3 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
}

@media (min-width: 960px) and (max-width: 1279px) {
  .main-div-pad {
    padding: 30px 40px;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .main-div-pad {
    padding: 30px;
  }
  .main-div-pad-cat {
    padding: 8px 40px;
  }
  .main-div-pad-x {
    padding: 8px !important;
  }
  .product-title{
    font-size:26px;
  }
}
@media (max-width: 599px) {
  .outlet-90 {
    min-height: calc(100vh - 56px);
    // margin-top: 56px;
  }
  .main-div-pad {
    padding: 10px;
  }
  .main-div-pad-cat {
    padding: 5px 0px;
  }
  .main-div-pad-x {
    padding: 5px !important;
  }
  .product-title{
    font-size:24px;
  }
  .heading {
    font-size: 22px;
  }
  .sub-heading {
    font-size: 18px;
  }
  .fs-md {
    font-size: 17px;
  }
  .fs-sm {
    font-size: 12px;
  }
  .fs-x-sm {
    font-size: 10px;
  }
  .paragragh {
    font-size: 14px;
  }
}

.close-icon {
  transition: 0.5s !important;
}
.close-icon:hover {
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px grey;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border: 1px thin black !important;
}

/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 0.8px !important;

}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-primary) !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: none !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: auto;
  position: absolute;
  top: 13px;
}

.mat-mdc-select-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  position: absolute !important;
  top: 14px !important;
}





//font-family
// .dancing-script {
//   font-family: "Dancing Script", cursive;
//   font-optical-sizing: auto;
// }

// .jacquard-12-regular {
//   font-family: "Jacquard 12", system-ui;
//   // font-style: normal;
// }

// .playfair-display {
//   font-family: "Playfair Display", serif;
//   font-optical-sizing: auto;
//   font-style: normal;
// }

// .poetsen-one-regular {
//   font-family: "Poetsen One", sans-serif;
//   font-style: normal;
// }
// .cutive-mono-regular {
//   font-family: "Cutive Mono", monospace;
//   font-style: normal;
// }

// .lobster-regular {
//   font-family: "Lobster", sans-serif;
//   font-style: normal;
// }

// .lora {
//   font-family: "Lora", serif;
//   font-optical-sizing: auto;
//   font-style: normal;
// }
// .merriweather-italic {
//   font-family: "Merriweather", serif;
//   font-style: italic;
// }
// .pacifico-regular {
//   font-family: "Pacifico", cursive;
//   font-weight: 400;
//   font-style: normal;
// }

// .josefin-sans {
//   font-family: "Josefin Sans", sans-serif;
//   font-optical-sizing: auto;
//   font-style: normal;
// }
// .Roboto {
//   font-family: "Roboto", sans-serif !important;
// }

// .OpenSans {
//   font-family: "Open Sans", sans-serif !important;
// }

// .Lato {
//   font-family: "Lato", sans-serif !important;
// }

// .Montserrat {
//   font-family: "Montserrat", sans-serif !important;
// }

// .SourceSansPro {
//   font-family: "Source Sans Pro", sans-serif !important;
// }

// .Poppins {
//   font-family: "Poppins", sans-serif;
//   font-optical-sizing: auto;
// }
// @import url(./assets/images/786riderlogo.png);

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Cutive+Mono&family=Jacquard+12&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lobster&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pacifico&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poetsen+One&display=swap');

.dancing-script {
  font-family: "Dancing Script", cursive;
}

.jacquard-12-regular {
  font-family: "Jacquard 12", system-ui !important;
}

.playfair-display {
  font-family: "Playfair Display", serif;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
}

.cutive-mono-regular {
  font-family: "Cutive Mono", monospace;
}

.lobster-regular {
  font-family: "Lobster", sans-serif;

}

.lora {
  font-family: "Lora", serif;

}
.merriweather-italic {
  font-family: "Merriweather", serif;

}
.pacifico-regular {
  font-family: "Pacifico", cursive;

}

.josefin-sans {
  font-family: "Josefin Sans", sans-serif;

}
.Roboto {
  font-family: "Roboto", sans-serif !important;
}

.OpenSans {
  font-family: "Open Sans", sans-serif !important;
}

.Lato {
  font-family: "Lato", sans-serif !important;
}

.Montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.SourceSansPro {
  font-family: "Source Sans Pro", sans-serif !important;
}

.Poppins {
  font-family: "Poppins", sans-serif;

}



.threeDStyle1 {
  // position: absolute;
  // top: 50%; 
  // right: 50%;
  // transform: translate(50%,-50%);
  // text-transform: uppercase;
  // font-family: verdana;
  // font-size: 25px;
  // font-weight: 700;
  // color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}

.threeDStyle2 {
  // font-size: 150px;
  // text-align: center;
  // font-family: default;
  transform-style: preserve-3d;
  text-shadow: 0 1px 1px #bbb, 0 2px 0 #999, 0 3px 0 #888, 0 4px 0 #777,
    0 5px 0 #666, 0 6px 0 #555, 0 7px 0 #444, 0 8px 0 #333, 0 9px 7px #302314;
  animation: text 5s alternate infinite;
}

.threeDStyle3 {
  // font:normal 60px Arial;
  // color:#FFFFFF;
  text-shadow: 0 1px 0 #ccc,
  0 2px 0 #c9c9c9,
  0 3px 0 #bbb,
  0 4px 0 #b9b9b9,
  0 5px 0 #aaa,
  0 6px 1px rgba(0,0,0,.1),
  0 0 5px rgba(0,0,0,.1),
  0 1px 3px rgba(0,0,0,.3),
  0 3px 5px rgba(0,0,0,.2),
  0 5px 10px rgba(0,0,0,.25),
  0 10px 10px rgba(0,0,0,.2),
  0 20px 20px rgba(0,0,0,.15);
  //  text-align:center;
 }

 .threeDStyle4 {
  box-sizing: content-box;
  border: none;
  font: normal 50px/normal "Chau Philomene One", Helvetica, sans-serif;
  // color: rgb(51, 51, 51);
  // text-align: center;
  // text-overflow: clip;
  letter-spacing: 3px;
  text-shadow: -3px 0 1px rgb(30,242,241) , 3px 0 1px rgb(246,5,10) ;
}

.threeDStyle5 {
  color: #70869d;
  letter-spacing: .15em;
  text-shadow: 
    -1px -1px 1px #efede3, 
    0px 1px 0 #2e2e2e, 
    0px 2px 0 #2c2c2c, 
    0px 3px 0 #2a2a2a, 
    0px 4px 0 #282828, 
    0px 5px 0 #262626, 
    0px 6px 0 #242424, 
    0px 7px 0 #222, 
    0px 8px 0 #202020, 
    0px 9px 0 #1e1e1e, 
    0px 10px 0 #1c1c1c, 
    0px 11px 0 #1a1a1a, 
    0px 12px 0 #181818, 
    0px 13px 0 #161616, 
    0px 14px 0 #141414, 
    0px 15px 0 #121212,
    2px 20px 5px rgba(0, 0, 0, 0.9),
    5px 23px 5px rgba(0, 0, 0, 0.3),
    8px 27px 8px rgba(0, 0, 0, 0.5),
    8px 28px 35px rgba(0, 0, 0, 0.9);
}


.threeDStyle6{
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // font-size:120px;
  letter-spacing:0.1em;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  text-shadow: 
            8px 8px #ff1f8f,
            20px 20px #000000;
}

